<template>
    <div class="homepage-wrapper">
<!--        <div id="preloader"></div>-->
      <TradingHomepageHeader/>
        <!-- header end -->
        <!-- Start Slider Area -->
        <div class="slide-area slide-area-3 fix heading-area" data-stellar-background-ratio="0.6" style="background: #222e3d !important;">
            <div class="display-table">
                <div class="display-table-cell">
                    <div class="container">
                        <div class="row">
                            <div class="text-center">
                              <br>
                              <br>
                              <h1 style="color:#ffffff" class="mt-3">Special Promotion</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->


<!--        <div class="about-area bg-color fix area-padding">-->
<!--            <div class="container">-->
<!--               &lt;!&ndash;BUY SGD 10, EXTRA SGD 2 (20%)&ndash;&gt;-->
<!--                <div class="row">-->
<!--                    <div class="support-all">-->
<!--                      <div class="row ">-->
<!--                        &lt;!&ndash; Start services &ndash;&gt;-->
<!--                        <div class="col-md-6 col-sm-6 col-xs-12 trading-flex-center">-->
<!--                          <img src="/assets/trading-assets/TransCrypt-spend10-v2-wo.jpg"  class="text-right  pl-2 pr-2 pb-2" style="max-width: 400px">-->
<!--                        </div>-->
<!--                        &lt;!&ndash; Start services &ndash;&gt;-->
<!--                        <div class="col-md-6 col-sm-6 col-xs-12">-->
<!--                          <h2>Spend $10 and Get Extra $2</h2>-->
<!--                          <p class="text-medium">Grow your investment portfolio by investing in cryptocurrency with TransCrypt. Get extra 20% rewards-->
<!--                            voucher in your TransCrypt account when you buy any amount of cryptocurrency. This means you get-->
<!--                            an extra $2 rewards voucher when you buy $10 worth of crypto.-->
<!--                          </p>-->
<!--                          <div>-->
<!--                            <h5 class="trading-text-black">Terms and Conditions</h5>-->
<!--                            <ol style="margin: 0 5px;list-style: inside" class="text-small">-->
<!--                              <li>-->
<!--                                You shall be entitled to receive 20% of the total amount that you spend to purchase Cryptocurrency-->
<!--                                on TransCrypt, in the form of SGD being credited into your TransCrypt voucher balance account-->
<!--                                (“Rewards”).-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                The Rewards shall be credited into your TransCrypt voucher balance account by the next working day-->
<!--                                after you have successfully purchased the Cryptocurrency.-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                The promotion period for the Rewards shall be from 29 Oct 2021 starting at 00:00 and ending on 11-->
<!--                                Nov 2021 at 23:59 Singapore time (“Promotion Period”).-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                The total Rewards that you are entitled to receive shall be capped at SGD20.00 and the Rewards may-->
<!--                                be accumulated in a single transaction or multiple transactions during the Promotion Period.-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                The Rewards are applicable to all new and existing customers of TransCrypt platform who uses the TransCrypt Trading service.-->
<!--                              </li>-->
<!--                              <li>-->
<!--                                We reserve the right to change these terms and conditions and the Rewards at any time we-->
<!--                                reasonably deem fit and further reserve the right to interpret or resolve any disputes in relation to these-->
<!--                                terms and conditions and/or the Rewards.-->
<!--                              </li>-->
<!--                            </ol>-->
<!--                          </div>-->
<!--                          &lt;!&ndash;                                <div class="about-support-services">&ndash;&gt;-->
<!--                          &lt;!&ndash;                                    <a class="support-images"><img&ndash;&gt;-->
<!--                          &lt;!&ndash;                                            src="/assets/remittance-assets/about-safe-icon.png" alt=""></a>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                    <div class="support-content">&ndash;&gt;-->
<!--                          &lt;!&ndash;                                      &ndash;&gt;-->
<!--                          &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                          &lt;!&ndash;                                </div>&ndash;&gt;-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--      <div class="area-padding fix" data-stellar-background-ratio="0.6" style="background-color: #f1f1f1 !important;">-->
<!--        <div class="container">-->
<!--          &lt;!&ndash;ZERO TRANSACTION FEES&ndash;&gt;-->
<!--          <div class="row">-->
<!--            <div class="col-md-12 col-sm-12 col-xs-12">-->
<!--              <div class="row">-->
<!--                &lt;!&ndash; Start services &ndash;&gt;-->
<!--                <div class="col-md-6 col-sm-6 col-xs-12 trading-flex-center">-->
<!--                  <img src="/assets/trading-assets/TransCrypt-zerofees-v2-wo.jpg"  class="text-right pl-2 pr-2 pb-2" style="max-width: 400px">-->
<!--                </div>-->
<!--                &lt;!&ndash; Start services &ndash;&gt;-->
<!--                <div class="col-md-6 col-sm-6 col-xs-12">-->
<!--                  <h2>Zero Transaction Fees</h2>-->
<!--                  <p class="text-medium">To celebrate the opening of TransCrypt Trading, we are offering zero transaction fees when you buy or sell-->
<!--                    cryptocurrency through TransCrypt. Take advantage of this special promotion and get started with your-->
<!--                    crypto investment journey!-->
<!--                  </p>-->
<!--                  <div>-->
<!--                    <h5 class="trading-text-black">Terms and Conditions</h5>-->
<!--                    <ol style="margin: 0 5px;list-style: inside" class="text-small">-->
<!--                      <li>-->
<!--                        You shall be entitled to enjoy zero dollars (0) transaction fee during the Promotion Period (as defined-->
<!--                        below) for any transaction on the TransCrypt platform (“Zero Fees Promotion”).-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        The Zero Fees Promotion shall be run from 29 Oct 2021 starting at 00:00 and ending on 28 Nov-->
<!--                        2021 at 23:59 Singapore time (“Promotion Period”).-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        The Zero Fees Promotion is applicable to all new and existing customers of TransCrypt platform who-->
<!--                        uses the TransCrypt Trading service-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        We reserve the right to change these terms and conditions and the Zero Fees Promotion at any time-->
<!--                        we reasonably deem fit and further reserve the right to interpret or resolve any disputes in relation to-->
<!--                        these terms and conditions and/or the Zero Fes Promotion.-->
<!--                      </li>-->
<!--                    </ol>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;                                <div class="about-support-services">&ndash;&gt;-->
<!--                  &lt;!&ndash;                                    <a class="support-images"><img&ndash;&gt;-->
<!--                  &lt;!&ndash;                                            src="/assets/remittance-assets/about-safe-icon.png" alt=""></a>&ndash;&gt;-->
<!--                  &lt;!&ndash;                                    <div class="support-content">&ndash;&gt;-->
<!--                  &lt;!&ndash;                                      &ndash;&gt;-->
<!--                  &lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                  &lt;!&ndash;                                </div>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!--SHOW WHEN THERE'S NO PROMOTION-->
      <div class="area-padding fix" data-stellar-background-ratio="0.6" style="background-color: #f1f1f1 !important;">
        <div class="container">
          <h3 class="text-center">Coming Soon !</h3>
        </div>
      </div>

        <!-- Start brand Banner area -->
        <div class="brand-area area-padding fix" data-stellar-background-ratio="0.6">
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="brand-text text-center">
                    <h3>Buy & sell cryptocurrency seamlessly, start investing now.</h3>
                    <br>
                    <br>
                    <router-link class="hire-btn gtm-get-started" to="/trading/user/register">Get started
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- End brand Banner area -->


        <!-- Start Footer Area -->
<!--        <Footer/>-->
      <TradingHomepageFooter/>
        <!-- End Footer Area -->
    </div>
</template>
<script>
    // import Footer from "../layouts/HomepageFooter"
    // import Header from "../layouts/HomepageHeader"

    import TradingHomepageHeader from "../../layouts/trading/TradingHomepageHeader"
    import TradingHomepageFooter from "../../layouts/trading/TradingHomepageFooter"

    export default {
        name: 'SpecialPromotionPage',
        components: {TradingHomepageHeader,TradingHomepageFooter},
        data() {
            return {
                debugConsole : false,
            }
        },
        metaInfo: {
            title: 'About TransCrypt | Money Transfer with Cryptocurrency',
            meta: [
                {
                    name: 'description',
                    content: ' Quick, Easy, and Seamless, TransCrypt is a one-stop cryptocurrency remittance service for people to make borderless money transfer with cryptocurrency.'
                }
            ]
        },
        mounted() {
            if(this.debugConsole)  console.debug("About Page Mounted");
            require('@/assets/js/main.js');

            //check if mean container is loaded
           if(this.debugConsole) console.log("Mean container loaded", window.jQuery('.mean-container'))
            if(window.jQuery('.mean-container').length === 0)
            {
                window.jQuery('nav#dropdown').meanmenu()
            }

        },
        methods: {
            goToHomepage() {
                this.$router.push('/home');
            },
            goToLogin(){
                this.$router.push('/account/login')
            },
            goToRegisterPage(){
                this.$router.push('/account/register');
            }
        }
    }

</script>
<style scoped>



</style>
